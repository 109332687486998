import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { footer } from "./footer.module.scss";
import { graphql, Link, useStaticQuery } from "gatsby";
const Footer = (props) => {

  const { allStrapiBlogs } = useStaticQuery(pageQuery);
  const { edges } = allStrapiBlogs;
  return (
    <Row>
      <Col>
        <div className={`${footer} footer section-dark`}>
          <Row className="p-5">
            <Col xl={12} lg={12} sm={12} md={12}>
              <StaticImage
                src="../../images/logo-white.png"
                className="logo-footer"
                alt=""
                width={148}
              ></StaticImage>
              <div className="logo-footer"></div>
            </Col>
            <Col>
              <Row>
                <Col xl={4} lg={4} sm={12} md={12} className="pr-5">
                  <div>
                    <label>&nbsp;</label>
                    <a href="#">PRIVACY POLICY</a>
                    <a href="#">GDPR STATEMENT</a>
                  </div>
                </Col>
                <Col xl={4} lg={4} sm={12} md={12} className="pr-5">
                  <div>
                    <label>LINKS</label>
                    <Link to="/problems">PROBLEMS WE SOLVE</Link>
                    <Link to="/solutions">SOLUTIONS WE OFFER</Link>
                    <Link to="/our-story">OUR STORY</Link>
                    <Link to="/blog">BLOG</Link>
                  </div>
                </Col>
                
                  <Col xl={4} lg={4} sm={12} md={12} className="pr-5">
                    <div>
                      <label>RECENT ARTICLES</label>
                      {/* <a href="components/blog/index">
                        Should I buy or not is the question
                      </a> */}
                      { edges && edges.slice(0,3).map ( (blog,indx) => (
                        <Link key={`blogLink_${indx}`} to={'/blogs/' + blog.node.url}>
                          { blog.node.ShortDescription ? blog.node.ShortDescription: "No blog posts published recently."}
                        </Link>
                      ))}
                    </div>
                  </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Footer;

export const pageQuery = graphql` 
  query FooterComponentBlogsQuery {
    allStrapiBlogs {
      edges {
        node {
          strapiId
          Title
          ShortDescription
          url
        }
      }
    }
  }`
;
