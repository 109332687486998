import * as React from "react";
import { useState, useEffect } from "react";
import { Form, Button, Modal, ProgressBar } from "react-bootstrap";

const ApplyNow = (props) => {
  const { show, handleClose, onSuccess,onError } = props;
  const businessErrorInit = {
    amountRequired: "required",
    businessSector: "required",
    businessName: "required",
    CRONumber: "required",
    businessAddress: "required",
    businessWebsite: "required",
  };
  const personalErrorsInit = {
    fullName: "required",
    contactNumber: "required",
    email: "required"
  };
  const [businessType, setBusinessType] = useState("business");

  const [form, setForm] = useState({});
  const [businessErrors, setBusinessErrors] = useState(businessErrorInit);
  const [personalErrors, setPersonalErrors] = useState(personalErrorsInit);

  const labels = {
    amountRequired: "Amount Required",
    businessSector: "Business Sector",
    businessName: "Business Name",
    CRONumber: "CRO Number",
    businessAddress: "Business Address",
    businessWebsite: "Business Website",
    fullName: "Full Name",
    contactNumber: "Contact Number",
    email: "Email",
  };

  const applyLabels = (formObj) => {
    const labeledForm = {...formObj};
    for (const [k, v] of Object.entries(formObj)) {
       console.log("Entry")
       labeledForm[k] = { value:v, label: labels[k]};
    }
    return labeledForm;
  }
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (businessType == 'business') {
      if (businessErrors[field] && value) {
        console.log("Setting business errors to null");
        setBusinessErrors({
          ...businessErrors,
          [field]: null,
        });
      } else if (!value || value.lenth === 0) {
        setBusinessErrors({
          ...businessErrors,
          [field]: "required",
        });
      }
    } else {
       if (personalErrors[field] && value) {
         console.log("Setting personal errors to null");
         setPersonalErrors({
           ...personalErrors,
           [field]: null,
         });
       } else if (!value || value.lenth === 0) {
         setPersonalErrors({
           ...personalErrors,
           [field]: "required",
         });
       }
    }
  };

  const hasErrors = (errors) => {
    let hasErrors = true;
    console.log("Errors", errors);
    for (const [k, v] of Object.entries(errors)) {
      if (v != null) {
        return true;
      } else {
        hasErrors = false;
      }
    }
    return hasErrors;
  };

  return (
    <Modal
      keyboard={false}
      show={show}
      onHide={(e) => {
        setForm({});
        setBusinessType("business");
        setBusinessErrors(businessErrorInit);
        setPersonalErrors(personalErrorsInit);
        handleClose(e);
      }}
      backdrop="static"
      className="apply-now"
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply Now</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* <div style={{ paddingBottom: 20 }}>
          <input
            type="radio"
            className="wizard-radio"
            name="businessType"
            checked={businessType === "business"}
            onChange={() => setBusinessType("business")}
            value="business"
          />
          Business
          <br />
          <input
            type="radio"
            className="wizard-radio"
            name="businessType"
            checked={businessType === "personal"}
            onChange={() => setBusinessType("personal")}
            value="personal"
          />
          Personal
        </div> */}
        {businessType === "business" && (
          <>
            <h4> Business</h4>

            <Form key={`form_b`}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Amount required</Form.Label>
                <Form.Control
                  type="number"
                  value={form["amountRequired"]}
                  onChange={(e) => setField("amountRequired", e.target.value)}
                  placeholder={``}
                />
                <br />
                <Form.Label>Business sector</Form.Label>
                <Form.Control
                  as="select"
                  value={form["businessSector"] || "default"}
                  onChange={(e) => setField("businessSector", e.target.value)}
                >
                  <option disabled={true} value="default">
                    Select Option
                  </option>
                  <option value="AGRICULTURE AND FORESTRY">
                    AGRICULTURE AND FORESTRY
                  </option>
                  <option value="CONSTRUCTION">CONSTRUCTION</option>
                  <option value="ELECTRICITY, GAS AND WATER SUPPLY">
                    ELECTRICITY, GAS AND WATER SUPPLY
                  </option>
                  <option value="FIN. INTERMED (EXCL. CREDIT INST'S/CENTRAL BANK)">
                    FIN. INTERMED (EXCL. CREDIT INST'S/CENTRAL BANK)
                  </option>
                  <option value="FISHING">FISHING</option>
                  <option value="HEALTH AND SOCIAL WORK">
                    HEALTH AND SOCIAL WORK
                  </option>
                  <option value="HOTELS AND RESTAURANTS">
                    HOTELS AND RESTAURANTS
                  </option>
                  <option value="MANUFACTURING">MANUFACTURING</option>
                  <option value="MINING AND QUARRYING">
                    MINING AND QUARRYING
                  </option>
                  <option value="OTHER COMMUNITY, SOCIAL AND PERSONAL SERVICES">
                    OTHER COMMUNITY, SOCIAL AND PERSONAL SERVICES
                  </option>
                  <option value="REAL ESTATE, RENTING AND BUSINESS">
                    REAL ESTATE, RENTING AND BUSINESS
                  </option>
                  <option value="TRANSPORT, STORAGE AND COMMUNICATIONS">
                    TRANSPORT, STORAGE AND COMMUNICATIONS
                  </option>
                  <option value="WHOLESALE/RETAIL TRADE & REPAIRS">
                    WHOLESALE/RETAIL TRADE & REPAIRS
                  </option>
                  <option value="CENTRAL GOVERNMENT">CENTRAL GOVERNMENT</option>
                  <option value="CREDIT INSTITUTIONS">
                    CREDIT INSTITUTIONS
                  </option>
                  <option value="EDUCATION (SCHOOLS AND COLLEGES)">
                    EDUCATION (SCHOOLS AND COLLEGES)
                  </option>
                  <option value="EXTRA-TERRITORIAL ORGANISATIONS AND BODIES">
                    EXTRA-TERRITORIAL ORGANISATIONS AND BODIES
                  </option>
                </Form.Control>
                <br />
                <Form.Label>Business name</Form.Label>
                <Form.Control
                  type="text"
                  value={form["businessName"]}
                  onChange={(e) => setField("businessName", e.target.value)}
                  placeholder={``}
                />
                <br />
                <Form.Label>CRO number</Form.Label>
                <Form.Control
                  type="text"
                  value={form["CRONumber"]}
                  onChange={(e) => setField("CRONumber", e.target.value)}
                  placeholder={``}
                />
                <br />
                <Form.Label>Business address</Form.Label>
                <Form.Control
                  type="text"
                  value={form["businessAddress"]}
                  onChange={(e) => setField("businessAddress", e.target.value)}
                  placeholder={``}
                />
                <br />
                <Form.Label>Business website</Form.Label>
                <Form.Control
                  type="text"
                  value={form["businessWebsite"]}
                  onChange={(e) => setField("businessWebsite", e.target.value)}
                  placeholder={``}
                />
                <br />
              </Form.Group>
            </Form>
          </>
        )}
        {businessType === "personal" && (
          <>
            <h4>Personal Information</h4>
            <Form key={`form_p`}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Full name</Form.Label>
                <Form.Control
                  type="text"
                  value={form["fullName"]}
                  onChange={(e) => setField("fullName", e.target.value)}
                  placeholder={``}
                />
                <br />
                <Form.Label>Contact number</Form.Label>
                <Form.Control
                  type="text"
                  value={form["contactNumber"]}
                  onChange={(e) => setField("contactNumber", e.target.value)}
                  placeholder={``}
                />
                <br />
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={form["email"]}
                  onChange={(e) => setField("email", e.target.value)}
                  placeholder={``}
                />
                <br />
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {businessType === "personal" && (
          <Button
            variant="light"
            disabled={hasErrors(businessErrors)}
            onClick={() => {
              setBusinessType("business");
            }}
          >
            Back
          </Button>
        )}
        <Button
          variant="light"
          disabled={hasErrors(businessType === "business" ? businessErrors : personalErrors)}
          onClick={() => {
            if (businessType === "business") {
              setBusinessType("personal");
            } else {
              const labelledForm = applyLabels(form);
              fetch("https://contact.tarin.ie/apply-now", {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(labelledForm),
              })
                .then(function (res) {
                  onSuccess();
                })
                .catch(function (res) {
                  console.error(res);
                  onError();
                });
            }
          }}
        >
          {businessType === "business" ? "Next" : "Apply"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApplyNow;
