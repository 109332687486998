import * as React from "react";
import { useState } from "react";
import {
  Navbar,
  Nav,
  Form,
  Button,
} from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ApplyNow from "../apply-now";
import {ToastContainer, toast } from "react-toastify";

const Navigation = (props) => {
  const [showApply, setShowApply] = useState(false);
  return (
    <Navbar sticky="top" bg="white" expand="lg">
      <Navbar.Brand>
        <Link to="/">
          <StaticImage
            width={150}
            alt="TARIN Logo"
            placeholder="blurred"
            src="../../images/logo-color.png"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto mr-lg-5">
          <Link
            activeClassName="nav-link active"
            className="nav-link"
            to="/problems"
          >
            PROBLEMS WE SOLVE{" "}
          </Link>
          <Link
            activeClassName="nav-link active"
            className="nav-link"
            to="/solutions"
          >
            SOLUTIONS WE OFFER
          </Link>
          <Link
            activeClassName="nav-link active"
            className="nav-link"
            to="/our-story"
          >
            OUR STORY
          </Link>
          <Link
            activeClassName="nav-link active"
            className="nav-link"
            to="/blog"
          >
            BLOG
          </Link>
        </Nav>
        <Form inline className="mr-lg-4">
          <Button onClick={() => setShowApply(true)} variant="solid-purple">
            Apply Now
          </Button>
        </Form>
        <ApplyNow
          show={showApply}
          onSuccess={() => {
            toast.success("Successfully submitted form", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowApply(false);
          }}
          onError={() => {
            toast.error("Error while submitted the form", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}
          handleClose={() => {
            setShowApply(false);
          }}
        ></ApplyNow>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          closeOnClick={true}
          theme="colored"
          limit={1}
          pauseOnHover
        />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;