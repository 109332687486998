import * as React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
const Banner = (props) => {
  const { children, title,backgroundClass, backgroundImg } = props;
  const [backgroundImageStyle, setBackgroundImageStyle] = useState({});
    useEffect(() => {
      if (backgroundImg) {
        if (backgroundClass !== "default-bg bg-to-buy") {
          setBackgroundImageStyle({
            background: `linear-gradient(
            0deg,
            rgba(33, 121, 191, 0.5),
            rgba(33, 121, 191, 0.5)
          ),
          url(${backgroundImg}) no-repeat`,
          });
        } else {
          setBackgroundImageStyle({
            background: `linear-gradient(
            0deg,
            rgba(33, 121, 191, 0.5),
            rgba(33, 121, 191, 0.5)
          ),
          url(${backgroundImg})`,
            backgroundSize: `cover`,
            backgroundPosition: `center`,
          });
        }
      }
    }, []);
  return (
      <Row className={`banner ${backgroundClass ? backgroundClass : 'default-bg'}`} style={backgroundImageStyle}>
      {children && (
        <Col lg={12}>
          <div className="title-container">{children}</div>
        </Col>
      )}
      {!children && (
        <Col lg={12}>
          <div className="title-container">
            <Row>
              <Col>
                <span className={`light-topic`}>{title}</span>
              </Col>
            </Row>
          </div>
        </Col>
      )}
    </Row>
  );
};
export default Banner;